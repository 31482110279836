import React from 'react';

interface ProjectTileCounterBadgeProps {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    value: string;
}

export const ProjectTileCounterBadge = ({
    icon: Icon,
    value,
}: ProjectTileCounterBadgeProps) => (
    <div className="flex h-6 w-18 items-center justify-center rounded-full bg-black/5">
        <div className="flex items-center gap-1.5">
            <Icon className="size-4 stroke-2" />
            <span className="text-xs font-medium tracking-[0.05rem]">
                {value}
            </span>
        </div>
    </div>
);
