import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { OmitStylesComponentProps } from '../../types';

import { AlertDialogOverlay } from './alert-dialog-overlay';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogContentVariants = cva('rocco-alert-dialog-content', {
    variants: {
        variant: {
            default:
                'fixed left-[50%] top-[50%] z-modal grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogContent = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Content>,
    OmitStylesComponentProps<typeof AlertDialogPrimitive.Content> &
        VariantProps<typeof alertDialogContentVariants>
>(({ variant, ...props }, ref) => (
    <AlertDialogPrimitive.Portal>
        <AlertDialogOverlay />
        <AlertDialogPrimitive.Content
            ref={ref}
            className={alertDialogContentVariants({ variant })}
            {...props}
        />
    </AlertDialogPrimitive.Portal>
));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;
