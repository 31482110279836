import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogOverlayVariants = cva('rocco-alert-dialog-overlay', {
    variants: {
        variant: {
            default:
                'fixed inset-0 z-modal bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogOverlay = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
    OmitStylesComponentProps<typeof AlertDialogPrimitive.Overlay> &
        VariantProps<typeof alertDialogOverlayVariants>
>(({ variant, ...props }, ref) => (
    <AlertDialogPrimitive.Overlay
        className={alertDialogOverlayVariants({ variant })}
        {...props}
        ref={ref}
    />
));

AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;
