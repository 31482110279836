import type { ImageModel } from '@rocco/types/image-model';
import type { TileableModel } from '@rocco/ui/tile-shared/view-models/tile';

export interface SubCategoryTileViewModel {
    id: number;
    title: string;
    link: string;
}

export interface CategoryTileViewModel {
    id: number;
    title: string;
    image: ImageModel;
    link: string;
    itemsCount: number;
    subCategories: SubCategoryTileViewModel[];
}

export const isCategoryTileViewModel = (
    model: TileableModel,
): model is CategoryTileViewModel =>
    'subCategories' in model && 'itemsCount' in model;
