import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { cn } from '@rocco/utils/cn';
import { OmitStylesComponentProps } from '../../types';

import { buttonVariants } from '../button';

export const AlertDialogAction = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Action>,
    OmitStylesComponentProps<typeof AlertDialogPrimitive.Action>
>((props, ref) => (
    <AlertDialogPrimitive.Action
        ref={ref}
        className={cn(buttonVariants())}
        {...props}
    />
));

AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;
