import type { ReactElement } from 'react';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDebounce } from 'ahooks';

export const KNOWN_PRIVATE_VIDEO_ERROR_CODES = ['150', '101'] as const;

// Two types of error were spot - string [150, 101] for youtube and Error for vimeo
// May be extended down the way
export type VideoPlayerError = string | Error;

export interface VideoPlayerProps {
    src: string | string[];
    coverSrc?: string;
    width?: number;
    height?: number;
    mode?: 'auto' | 'mouseover' | 'default';
    delay?: number;
    controls?: boolean;
    loop?: boolean;
    playsInline?: boolean;
    playIcon?: ReactElement;
    fill?: boolean;
    onStart?: () => void;
    onPlay?: () => void;
    onPause?: () => void;
    onEnded?: () => void;
    onReady?: () => void;
    onError: (e?: VideoPlayerError) => void;
}

export const VideoPlayer = forwardRef<HTMLDivElement, VideoPlayerProps>(
    (props, ref) => {
        const {
            src,
            coverSrc,
            width = '100%',
            height = '100%',
            mode = 'default',
            playsInline,
            controls = true,
            loop,
            delay = 500,
            fill = false,
            onStart,
            onPlay,
            onPause,
            onEnded,
            onReady,
            onError,
        } = props;

        const autoplay = mode === 'auto';
        const autoplayOnMouseOver = mode === 'mouseover';
        const muted = autoplay || autoplayOnMouseOver; // we always mute the video when autoplay
        const light = coverSrc ?? !(autoplay || autoplayOnMouseOver); // use "light" mode if not autoplay or with explicit cover image

        const [playing, setPlaying] = useState(autoplay);
        const [playError, setPlayError] = useState<ReactElement>();
        const [mouseOver, setMouseOver] = useState(false);

        const debouncedMouseOver = useDebounce(mouseOver, { wait: delay });

        useEffect(() => {
            if (!autoplayOnMouseOver) return;
            setPlaying(debouncedMouseOver);
        }, [autoplayOnMouseOver, debouncedMouseOver]);

        useEffect(() => {
            setPlaying(autoplay);
        }, [autoplay]);

        const sizeProps = { width, height };
        const wrapperProps = autoplayOnMouseOver
            ? {
                  style: {
                      ...sizeProps,
                  },
                  onMouseOver: () => {
                      setMouseOver(true);
                  },
                  onMouseLeave: () => {
                      setMouseOver(false);
                  },
              }
            : { style: sizeProps };

        const reactPlayerProps = {
            ...sizeProps,
            config: {
                file: {
                    attributes: {
                        style: {
                            ...sizeProps,
                            objectFit: fill ? 'cover' : undefined,
                        },
                    },
                },
            },
            url: src,
            light: light,
            playing: playing,
            muted: muted,
            loop: !!loop,
            controls: !!controls,
            // https://www.npmjs.com/package/react-player
            // seems a typo but it is true in react-player
            playsinline: !!playsInline,
            onStart: onStart,
            onPlay: onPlay,
            onPause: onPause,
            onEnded: onEnded,
            onReady: onReady,
            onClickPreview: () => {
                setPlaying(true);
            },
            onError: (e?: VideoPlayerError) => {
                onError && onError(e);
                setPlayError(
                    <div className="size-full flex justify-center items-center">
                        <span>The video can not be played.</span>
                    </div>,
                );
            },
        };

        const renderVideo = () => <ReactPlayer {...reactPlayerProps} />;

        return (
            <div {...wrapperProps} ref={ref}>
                {playError || renderVideo()}
            </div>
        );
    },
);

VideoPlayer.displayName = 'VideoPlayer';
