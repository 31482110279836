import * as React from 'react';
import { useCarousel } from './carousel';
import { cn } from '@rocco/utils/cn';

export const CarouselPagination = () => {
    const { currentSlide, totalSlides } = useCarousel();

    return (
        <div className="rocco-carousel-pagination z-10 flex justify-center gap-1 items-center">
            {[...Array(totalSlides).keys()].map(key => (
                <div
                    key={key}
                    className={cn(
                        'size-2 bg-white opacity-40 rounded-full',
                        currentSlide === key && 'opacity-100',
                    )}
                />
            ))}
        </div>
    );
};
