import React from 'react';
import { useCarousel } from './carousel';
import { cn } from '@rocco/utils/cn';
import { cva, VariantProps } from 'class-variance-authority';

const _carouselContentVariants = cva(
    'rocco-carousel-content overflow-hidden h-full select-none',
    {
        variants: {
            variant: {
                default: '',
                fullPage: 'md:px-12 px-6',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

interface CarouselContentProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof _carouselContentVariants> {}

export const CarouselContent = ({
    variant,
    className,
    ...props
}: CarouselContentProps) => {
    const { carouselRef } = useCarousel();

    return (
        <div
            ref={carouselRef}
            className={cn(_carouselContentVariants({ variant }))}
        >
            <div className={cn('flex h-full', className)} {...props} />
        </div>
    );
};
