import type { TileableModel } from '@rocco/ui/tile-shared/view-models/tile';
import type { ImageModel } from '@rocco/types/image-model';

export interface ArticleTileViewModel {
    id: number;
    title: string;
    label: string;
    link: string;
    image: ImageModel;
    video: string[];
    category: string;
    categoryLabel: string;
    date: string;
    readTime: string;
    homePinned: boolean;
    professional: {
        id: number;
        name: string;
    };
    badge: string | null;
    variant?: 'featured' | 'default';
}

export const isArticleTileViewModel = (
    model: TileableModel,
): model is ArticleTileViewModel => 'date' in model && 'readTime' in model;
