import React from 'react';
import { cn } from '@rocco/utils/cn';

export const CarouselItem = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            role="group"
            aria-roledescription="slide"
            className={cn(
                'min-w-0 basis-full shrink-0 grow-0 h-full',
                className,
            )}
            {...props}
        />
    );
};
