import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { cn } from '@rocco/utils/cn';
import { OmitStylesComponentProps } from '../../types';

import { buttonVariants } from '../button';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogCancelVariants = cva('rocco-alert-dialog-cancel', {
    variants: {
        variant: {
            default: 'mt-2 sm:mt-0',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogCancel = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
    OmitStylesComponentProps<typeof AlertDialogPrimitive.Cancel> &
        VariantProps<typeof alertDialogCancelVariants>
>(({ variant, ...props }, ref) => (
    <AlertDialogPrimitive.Cancel
        ref={ref}
        className={cn(
            buttonVariants({ color: 'outline' }),
            alertDialogCancelVariants({ variant }),
        )}
        {...props}
    />
));

AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;
