import React from 'react';
import { useHydrated } from 'remix-utils';
import { AdminContextMenu } from './AdminContextMenu';
import type { TileViewModel, TileableModel } from '../view-models/tile';

interface AdminTileProps {
    children: React.ReactNode;
    tile: TileViewModel<TileableModel>;
    actions: {
        onEdit: () => void;
        onRepositionImage: () => void;
        onDelete?: () => void;
        onTogglePin?: () => void;
    };
}

export const AdminTile: React.FC<AdminTileProps> = ({
    children,
    tile,
    actions,
}) => {
    const hydrated = useHydrated();
    const { adminStatusInfo } = tile;
    if (!adminStatusInfo || !hydrated) {
        return <>{children}</>;
    }

    return (
        <AdminContextMenu {...actions} statusInfo={adminStatusInfo}>
            {children}
        </AdminContextMenu>
    );
};
