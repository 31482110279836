import type { TileableModel } from '@rocco/ui/tile-shared/view-models/tile';
import type { ImageModel } from '@rocco/types/image-model';

export interface ProjectTileViewModel {
    id: number;
    title: string;
    link: string;
    images: ImageModel[];
    video: string[];
    hasAwards: boolean;
    badge: string | null;
    viewedCount?: number;
    savedCount?: number;
    professional: {
        id: number;
        name: string;
        link: string;
    };
}

export const isProjectTileViewModel = (
    model: TileableModel,
): model is ProjectTileViewModel =>
    model && 'hasAwards' in model && 'badge' in model;
