import type { ContextMenuItem } from '../components/ContextMenu';

/**
 * Filters the provided menu items to include only those that should be shown or are separators.
 * Additionally, it removes unnecessary separators (e.g., duplicated or those without items above/below).
 *
 * @param menuItems - The array of menu items to filter.
 * @returns The filtered array of menu items.
 */
export const filterMenuItems = (
    menuItems: ContextMenuItem[],
): ContextMenuItem[] => {
    // Filter menu items to include only those that should be shown or are separators
    const filteredMenuItems = menuItems.filter(
        item =>
            item.type === 'separator' ||
            !item.showCondition ||
            item.showCondition(),
    );

    // Filter out unnecessary separators
    return filteredMenuItems.reduce((acc, item, index, allItems) => {
        if (item.type !== 'separator') {
            acc.push(item);
            return acc;
        }

        const prevItem = acc[acc.length - 1];
        const nextItem = allItems[index + 1];

        // Keep separator if it's not at the start or end, and not adjacent to another separator
        if (
            index > 0 &&
            index < allItems.length - 1 &&
            prevItem &&
            prevItem.type !== 'separator' &&
            nextItem &&
            nextItem.type !== 'separator'
        ) {
            acc.push(item);
        }

        return acc;
    }, [] as ContextMenuItem[]);
};
