/**
 * Converts a monetary value to a formatted string representation.
 *
 * @param {number} value - The monetary value to format.
 * @param {string} currency - The currency code (e.g., 'USD', 'EUR', 'GBP').
 * @param {boolean} isSmallestUnit - If true, converts the value from the smallest unit (e.g., cents) to the major unit (e.g., dollars).
 * @returns {string} - The formatted monetary value as a string.
 */
export function formatPrice(
    value: number,
    currency: string,
    isSmallestUnit: boolean = false,
): string | null {
    if (value < 1) {
        return null;
    }

    // TODO: use { minimumFractionDigits: 2, trailingZeroDisplay: 'stripIfInteger' } option for NumberFormat after upgrade to node >= 19
    const resultPrice = isSmallestUnit ? value / 100.0 : value;
    const minimumFractionDigits = Number.isInteger(resultPrice) ? 0 : 2;

    return Intl.NumberFormat('en-NZ', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits,
    }).format(resultPrice);
}
