import React from 'react';
import { CalendarArrowUp, CalendarPlus, CalendarCheck2 } from 'lucide-react';

type TimestampInfoActions = 'created' | 'published' | 'approved';

interface TileAdminTimestampInfoProps {
    action: TimestampInfoActions;
    userFullName: string;
    date: string;
}

const TileAdminTimestampInfoIcon = ({
    action,
}: {
    action: TimestampInfoActions;
}) => {
    let Icon;

    switch (action) {
        case 'created':
            Icon = CalendarPlus;
            break;

        case 'published':
            Icon = CalendarArrowUp;
            break;

        case 'approved':
            Icon = CalendarCheck2;
            break;

        default:
            return null;
    }

    return <Icon className="size-4" />;
};

// TODO: Remove disable when it's used (It will be used for admin context menu stats)
export const TileAdminTimestampInfo = (props: TileAdminTimestampInfoProps) => {
    const { action, userFullName, date } = props;

    const altLabel = `${action.charAt(0).toUpperCase() + action.slice(1)} by ${userFullName} on ${date}`;

    return (
        <div
            className="flex items-center justify-between gap-8 cursor-default"
            title={altLabel}
        >
            <div className="flex items-center gap-2">
                <TileAdminTimestampInfoIcon action={action} />
                <span className="text-xs font-normal leading-normal text-gray-900">
                    {userFullName}
                </span>
            </div>
            <span className="text-xs font-normal leading-normal text-gray-400">
                {date}
            </span>
        </div>
    );
};
