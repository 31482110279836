import * as React from 'react';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { cn } from '@rocco/utils/cn';
import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const contextMenuLabelVariants = cva('rocco-context-menu-label', {
    variants: {
        variant: {
            default: 'px-2 py-1.5 text-sm font-semibold text-foreground',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const ContextMenuLabel = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Label>,
    OmitStylesComponentProps<typeof ContextMenuPrimitive.Label> &
        VariantProps<typeof contextMenuLabelVariants> & { inset?: boolean }
>(({ inset, variant, ...props }, ref) => (
    <ContextMenuPrimitive.Label
        ref={ref}
        className={cn(contextMenuLabelVariants({ variant }), inset && 'pl-8')}
        {...props}
    />
));

ContextMenuLabel.displayName = ContextMenuPrimitive.Label.displayName;
