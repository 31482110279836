import React from 'react';
import type {
    TileViewModel,
    TileableModel,
} from '@rocco/ui/tile-shared/view-models/tile';
import { isProductTileViewModel } from '@rocco/ui/tile-product/view-models/product';
import { ProductTile } from '@rocco/ui/tile-product/components/ProductTile';
import { isArticleTileViewModel } from '@rocco/ui/tile-article/view-models/article';
import { ArticleTile } from '@rocco/ui/tile-article/components/ArticleTile';
import {
    type ProfessionalTileViewModel,
    isProfessionalTileViewModel,
} from '@rocco/ui/tile-professional/view-models/professional';
import { ProfessionalTile } from '@rocco/ui/tile-professional/components/ProfessionalTile';
import { isProjectTileViewModel } from '@rocco/ui/tile-project/view-models/project';
import { ProjectTile } from '@rocco/ui/tile-project/components/ProjectTile';

import {
    type CategoryTileViewModel,
    isCategoryTileViewModel,
} from '@rocco/ui/tile-category/view-models/category';
import { CategoryTile } from '@rocco/ui/tile-category/components/CategoryTile';
import { AdminTile } from './AdminTile';

interface TileProps {
    tile: TileViewModel<TileableModel>;
    showTypeBadge?: boolean;
    preventScrollReset?: boolean;
    adminActions?: {
        onEdit: () => void;
        onRepositionImage: () => void;
        onDelete?: () => void;
        onTogglePin?: () => void;
    };
}

/**
 * The Tile component is the main component for rendering any type of tile in the application.
 * It takes a TileViewModel with a TileableModel and determines the specific tile component to render based on the model type.
 * This component serves as an adaptor to specific tiles, keeping the logic simple and focused on rendering the correct tile.
 */
export const Tile = ({
    tile,
    showTypeBadge,
    preventScrollReset,
    adminActions,
}: TileProps) => {
    const renderTile = (children: React.ReactNode) => {
        if (tile.adminStatusInfo && adminActions) {
            return (
                <AdminTile tile={tile} actions={adminActions}>
                    {children}
                </AdminTile>
            );
        }

        return <>{children}</>;
    };

    if (isProductTileViewModel(tile.model)) {
        return renderTile(
            <ProductTile
                {...tile}
                model={
                    showTypeBadge
                        ? {
                              ...tile.model,
                              badge: tile.model.badge || 'Product',
                          }
                        : tile.model
                }
            />,
        );
    }

    if (isProfessionalTileViewModel(tile.model)) {
        return renderTile(
            <ProfessionalTile
                {...(tile as TileViewModel<ProfessionalTileViewModel>)}
            />,
        );
    }

    if (isProjectTileViewModel(tile.model)) {
        return renderTile(
            <ProjectTile
                {...tile}
                model={
                    showTypeBadge
                        ? {
                              ...tile.model,
                              badge: tile.model.badge || 'Project',
                          }
                        : tile.model
                }
            />,
        );
    }

    if (isArticleTileViewModel(tile.model)) {
        return renderTile(
            <ArticleTile
                {...tile}
                model={
                    showTypeBadge
                        ? {
                              ...tile.model,
                              badge: tile.model.badge || 'Article',
                          }
                        : tile.model
                }
            />,
        );
    }

    if (isCategoryTileViewModel(tile.model)) {
        return (
            <CategoryTile
                {...(tile as TileViewModel<CategoryTileViewModel>)}
                preventScrollReset={preventScrollReset}
            />
        );
    }

    // This fallback UI is displayed when an unsupported tile type is encountered.
    return process.env.NODE_ENV === 'development' ? (
        <div className="flex h-full flex-col items-center justify-center gap-4 rounded border border-destructive p-4">
            <span className="font-bold text-destructive">
                Error: Unsupported tile type
            </span>
            <span className="text-gray-700">
                The tile type you are trying to render is not supported.
                <br />
                Did you forget to implement it at:
                <br />
                <code className="bg-accent text-sm text-accent-foreground">
                    /app/ui/tile-shared/components/Tile.tsx
                </code>
            </span>
        </div>
    ) : null;
};
