import type { ImageModel } from '@rocco/types/image-model';
import type { TileableModel } from '@rocco/ui/tile-shared/view-models/tile';

export interface ProductPriceTileViewModel {
    amount: number;
    discountedAmount: number;
    isRange: boolean;
    currency: 'NZD' | 'AUD';
}

export interface ProductVariantTileViewModel {
    color: string;
    image: ImageModel;
}

export interface ProductTileViewModel {
    id: number;
    title: string;
    images: ImageModel[];
    video: string[];
    link: string;
    price: ProductPriceTileViewModel | null;
    variants: ProductVariantTileViewModel[] | null;
    hasAwards: boolean;
    draft: boolean;
    badge: string | null;
    pendingApproval: boolean;
    canPurchase: boolean;
    professional: {
        id: number;
        name: string;
        link: string;
    };
    seoDescription: string;
}

export const isProductTileViewModel = (
    model: TileableModel,
): model is ProductTileViewModel =>
    model && 'price' in model && 'variants' in model;
