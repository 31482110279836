import React from 'react';
import { cn } from '@rocco/utils/cn';
import type { ProductPriceTileViewModel } from '@rocco/ui/tile-product/view-models/product';
import { formatPrice } from '@rocco/utils/format-price';

interface ProductTilePriceProps {
    price: ProductPriceTileViewModel | null;
}

export const ProductTilePrice = (props: ProductTilePriceProps) => {
    const { price } = props;

    // if price == null - we have `Price on Request` situation for this item - show nothing
    if (!price) {
        return null;
    }

    // we have some pricing - let's show it :)
    const hasDiscount =
        price.discountedAmount > 0 && price.discountedAmount < price.amount;

    const newLocal = hasDiscount && 'text-brick-50';
    return (
        <div className="flex gap-2">
            {price.isRange && (
                <span className="text-sm md:text-base font-medium leading-none">
                    From
                </span>
            )}

            {hasDiscount && (
                <span className="text-sm md:text-base font-medium leading-none text-gray-500 line-through">
                    {formatPrice(price.amount, price.currency, true)}
                </span>
            )}

            <span
                className={cn(
                    'text-sm md:text-base font-medium leading-none',
                    newLocal,
                )}
            >
                {formatPrice(
                    hasDiscount ? price.discountedAmount : price.amount,
                    price.currency,
                    true,
                )}
            </span>
        </div>
    );
};
