import React from 'react';
import { ReactComponent as ArrowRightIcon } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { Button } from '@rocco/components/button';
import type { ArticleTileViewModel } from '@rocco/ui/tile-article/view-models/article';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import { TileMedia } from '@rocco/ui/tile-shared/components/TileMedia';
import { TileBadge } from '@rocco/ui/tile-shared/components/TileBadge';
import { formatDateToDDMMYY } from '@rocco/utils/format-date';
import { Link } from '@remix-run/react';
import { TileFollowButton } from '@rocco/ui/tile-shared/components/TileFollowButton';
import { cva } from 'class-variance-authority';

const _articleTileContentVariants = cva('', {
    variants: {
        variant: {
            featured:
                'absolute inset-x-0 bottom-0 flex items-end justify-between gap-18 px-[1.83rem] pb-[2.12rem] text-gray-50',
            default: 'pt-4 text-[#515151]',
        },
    },
    defaultVariants: {
        variant: 'featured',
    },
});

const _articleTileTitleVariants = cva(
    'text-base font-medium tracking-[0.02rem]',
    {
        variants: {
            variant: {
                featured: 'pr-[5rem]',
                default: 'line-clamp-3 text-gray-900',
            },
        },
        defaultVariants: {
            variant: 'featured',
        },
    },
);

const _articleButtonVariants = cva('', {
    variants: {
        variant: {
            featured: '',
            default: 'hidden',
        },
    },
});

export const ArticleTile = (props: TileViewModel<ArticleTileViewModel>) => {
    const { aspectRatio, model: article } = props;
    const { variant = 'featured' } = article;

    return (
        <Link to={article.link} className="h-full">
            <div className="flex h-full flex-col gap-4">
                <div className="group relative h-full">
                    <TileMedia
                        aspectRatio={aspectRatio}
                        images={[article.image]}
                        video={article.video}
                        gradiantOverlay={true}
                    />
                    {article.badge && (
                        <div className="absolute left-4 top-4">
                            <TileBadge title="Article" />
                        </div>
                    )}
                    <div className="absolute right-4 top-3.5 flex gap-1 md:invisible md:group-hover:visible">
                        <TileFollowButton
                            pinId={article.id}
                            pinType="ArticlePin"
                        />
                    </div>
                    <div className={_articleTileContentVariants({ variant })}>
                        <div className="grid w-full grid-cols-[1fr_auto] gap-2 items-end">
                            <div className="flex flex-col gap-3 md:gap-[0.5625rem]">
                                <span
                                    className={_articleTileTitleVariants({
                                        variant,
                                    })}
                                >
                                    {article.title}
                                </span>
                                <span className="line-clamp-1 text-ellipsis leading-[1.1375rem] text-3.5 font-normal md:text-[0.875rem] tracking-[0.0175rem]">
                                    {article.date
                                        ? `${formatDateToDDMMYY(article.date)} - `
                                        : ''}
                                    Written by {article.label}
                                </span>
                            </div>
                            <div
                                className={_articleButtonVariants({ variant })}
                            >
                                <Button
                                    size="square42"
                                    color="primary"
                                    aria-label="Read article"
                                >
                                    <ArrowRightIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};
