import React from 'react';
import { Badge } from '@rocco/components/badge';

interface TileBadgeProps {
    title: string;
}

export const TileBadge = (props: TileBadgeProps) => (
    <Badge color="secondary" text="small">
        {props.title.toUpperCase()}
    </Badge>
);
