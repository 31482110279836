import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogTitleVariants = cva('rocco-alert-dialog-title', {
    variants: {
        variant: {
            default: 'text-lg font-semibold',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogTitle = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Title>,
    OmitStylesComponentProps<typeof AlertDialogPrimitive.Title> &
        VariantProps<typeof alertDialogTitleVariants>
>(({ variant, ...props }, ref) => (
    <AlertDialogPrimitive.Title
        ref={ref}
        className={alertDialogTitleVariants({ variant })}
        {...props}
    />
));

AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;
