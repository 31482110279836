import * as React from 'react';

import { OmitStylesHTMLAttributes } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogFooterVariants = cva('rocco-alert-dialog-footer', {
    variants: {
        variant: {
            default:
                'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogFooter = ({
    variant,
    ...props
}: OmitStylesHTMLAttributes<HTMLDivElement> &
    VariantProps<typeof alertDialogFooterVariants>) => (
    <div className={alertDialogFooterVariants({ variant })} {...props} />
);

AlertDialogFooter.displayName = 'AlertDialogFooter';
