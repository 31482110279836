import * as React from 'react';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const contextMenuContentVariants = cva('rocco-context-menu-content', {
    variants: {
        variant: {
            default:
                'z-50 min-w-[15rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in fade-in-80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const ContextMenuContent = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Content>,
    OmitStylesComponentProps<typeof ContextMenuPrimitive.Content> &
        VariantProps<typeof contextMenuContentVariants>
>(({ variant, ...props }, ref) => (
    <ContextMenuPrimitive.Portal>
        <ContextMenuPrimitive.Content
            ref={ref}
            className={contextMenuContentVariants({ variant })}
            {...props}
        />
    </ContextMenuPrimitive.Portal>
));

ContextMenuContent.displayName = ContextMenuPrimitive.Content.displayName;
