// date format as DD.MM.YY
import dayjs from 'dayjs';

export function formatDateToDDMMYY(date: string | number | Date): string {
    let dayjsDate;

    if (typeof date === 'number') {
        // Handle both millisecond and second timestamps
        dayjsDate = dayjs(date < 10000000000 ? date * 1000 : date);
    } else if (typeof date === 'string' && /^\d+$/.test(date)) {
        // Handle numeric strings as timestamps
        const timestamp = parseInt(date, 10);
        dayjsDate = dayjs(
            timestamp < 10000000000 ? timestamp * 1000 : timestamp,
        );
    } else {
        dayjsDate = dayjs(date);
    }

    return dayjsDate.isValid() ? dayjsDate.format('DD.MM.YY') : '';
}
