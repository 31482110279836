import React from 'react';
import type { PropsWithChildren } from 'react';
import { ContextMenu as RoccoContextMenu } from '@rocco/components/content-menu/context-menu';
import { ContextMenuContent as RoccoContextMenuContent } from '@rocco/components/content-menu/context-menu-content';
import { ContextMenuItem as RoccoContextMenuItem } from '@rocco/components/content-menu/context-menu-item';
import { ContextMenuTrigger as RoccoContextMenuTrigger } from '@rocco/components/content-menu/context-menu-trigger';
import { ContextMenuLabel as RoccoContextMenuLabel } from '@rocco/components/content-menu/context-menu-label';
import { ContextMenuSeparator as RoccoContextMenuSeparator } from '@rocco/components/content-menu/context-menu-separator';
import { filterMenuItems } from '../utils/filter-menu-items';
import { cva, VariantProps } from 'class-variance-authority';

// Interface for labels in the ContextMenu (no onClick handler)
interface ContextMenuLabelProps {
    type: 'label';
    render: () => React.ReactNode; // Function to render the element
    showCondition?: () => boolean; // Function to determine if the element should be shown (can be undefined for always visible items)
}

// Interface for items in the ContextMenu (with onClick handler)
interface ContextMenuItemProps {
    type: 'item';
    onClick: () => void;
    render: () => React.ReactNode; // Function to render the element
    showCondition?: () => boolean; // Function to determine if the element should be shown (can be undefined for always visible items)
    destructive?: boolean; // if this item should have `destructive` variant
}

// Interface for the separator element in the ContextMenu
// This is separated from ContextMenuElementProps as it does not require a showCondition or render function
interface ContextMenuSeparatorProps {
    type: 'separator';
}

export type ContextMenuItem =
    | ContextMenuLabelProps
    | ContextMenuItemProps
    | ContextMenuSeparatorProps;

const _contextMenuEmptyVariants = cva('bg-unset', {
    variants: {
        emptyHeight: {
            full: 'h-full',
            default: '',
        },
    },
    defaultVariants: {
        emptyHeight: 'default',
    },
});

interface ContextMenuProps
    extends PropsWithChildren,
        VariantProps<typeof _contextMenuEmptyVariants> {
    menuItems: ContextMenuItem[];
}

/**
 * Local ContextMenuItem component renders individual items within the ContextMenu.
 * It handles three types of elements: 'label', 'item', and 'separator'.
 * - For 'separator', it renders a RoccoContextMenuSeparator.
 * - For 'label', it renders a RoccoContextMenuLabel with the provided render function.
 * - For 'item', it renders a RoccoContextMenuItem with the provided render function.
 * - For 'item' with the `destructive` property set to true, it renders a RoccoContextMenuItem with a destructive style.
 *
 * @param props - The properties for the ContextMenuItem component, which can be either
 *                ContextMenuElementProps (excluding 'showCondition') or ContextMenuSeparatorProps.
 * @returns A React element representing the menu item.
 */
const ContextMenuItem = (props: ContextMenuItem) => {
    if (props.type === 'separator') {
        return <RoccoContextMenuSeparator />;
    }

    if (props.type === 'label') {
        return <RoccoContextMenuLabel>{props.render()}</RoccoContextMenuLabel>;
    }

    return (
        <RoccoContextMenuItem
            color={props.destructive ? 'destructive' : 'default'}
            onClick={props.onClick}
            cursor={'pointer'}
        >
            {props.render()}
        </RoccoContextMenuItem>
    );
};

/**
 * ContextMenu component renders a context menu with various elements such as labels, items, and separators.
 * It filters the menu items based on their show conditions and ensures separators are only shown when necessary.
 * If there are no items to render, {children} will be returned as is and no context menu wrapper will be added.
 *
 * Example usage:
 *
 * <ContextMenu menuItems={[
 *     { type: 'label', showCondition: () => true, render: () => <span>Label 1</span> },
 *     { type: 'item', showCondition: () => true, render: () => <span>Item 1</span> },
 *     { type: 'separator' },
 *     { type: 'item', destructyive: true, showCondition: () => false, render: () => <span>Item 2</span> },
 *     { type: 'label', showCondition: () => true, render: () => <span>Label 2</span> },
 * ]}>
 *     <div>Right Click to Open Menu</div>
 * </ContextMenu>
 */
export const ContextMenu = (props: ContextMenuProps) => {
    const { children, menuItems, emptyHeight } = props;
    const filteredMenuItems = filterMenuItems(menuItems);

    return filteredMenuItems.length > 0 ? (
        <RoccoContextMenu>
            <RoccoContextMenuTrigger>{children}</RoccoContextMenuTrigger>
            <RoccoContextMenuContent>
                {filteredMenuItems.map((menuItem, index) => (
                    <ContextMenuItem key={index} {...menuItem} />
                ))}
            </RoccoContextMenuContent>
        </RoccoContextMenu>
    ) : (
        <div className={_contextMenuEmptyVariants({ emptyHeight })}>
            {children}
        </div>
    );
};
