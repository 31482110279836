import * as React from 'react';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const contextMenuSeparatorVariants = cva('rocco-context-menu-separator', {
    variants: {
        variant: {
            default: '-mx-1 my-1 h-px bg-border',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const ContextMenuSeparator = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Separator>,
    OmitStylesComponentProps<typeof ContextMenuPrimitive.Separator> &
        VariantProps<typeof contextMenuSeparatorVariants>
>(({ variant, ...props }, ref) => (
    <ContextMenuPrimitive.Separator
        ref={ref}
        className={contextMenuSeparatorVariants({ variant })}
        {...props}
    />
));

ContextMenuSeparator.displayName = ContextMenuPrimitive.Separator.displayName;
