import { useAtomValue } from 'jotai';
import { Link } from '@remix-run/react';
import { uiContextAtomTileActions } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import type {
    CategoryTileViewModel,
    SubCategoryTileViewModel,
} from '@rocco/ui/tile-category/view-models/category';
import { TileMedia } from '@rocco/ui/tile-shared/components/TileMedia';
import { formatToThousandsDelimiter } from '@rocco/utils/format-number';

const MAX_SUB_CATEGORIES = 5;

export const CategoryTile = (props: TileViewModel<CategoryTileViewModel>) => {
    const { aspectRatio, model: category, preventScrollReset } = props;
    const actionsContext = useAtomValue(uiContextAtomTileActions);
    const { onCategoryClick } = actionsContext?.trackingConfig ?? {};

    return (
        <div className="group flex h-full max-w-[25.3125rem] flex-col gap-[1.125rem] md:gap-[1.2rem]">
            <Link
                to={category.link}
                preventScrollReset={!!preventScrollReset}
                onClick={() => onCategoryClick?.(category.id)}
            >
                <div className="group relative h-full overflow-hidden rounded-2.5">
                    <TileMedia
                        aspectRatio={aspectRatio}
                        images={[category.image]}
                    />

                    <div className="hidden md:block absolute inset-0 bg-gray-900/10 opacity-0 transition-opacity duration-300 md:group-hover:opacity-100 rounded-tile" />

                    <div className="absolute inset-0 flex size-full items-center justify-center">
                        <div className="flex items-start justify-center gap-2 px-8 text-gray-50">
                            <span className="text-center text-2xl">
                                {category.title}
                                <sup className="top-[-0.35rem] ml-2 text-3.5">
                                    {formatToThousandsDelimiter(
                                        category.itemsCount,
                                    )}
                                </sup>
                            </span>
                        </div>
                    </div>
                </div>
            </Link>

            <ul className="flex flex-col pl-[0.125rem] gap-[0.34375rem] md:pl-0 md:gap-1 md:pb-3">
                {category.subCategories
                    ?.slice(0, MAX_SUB_CATEGORIES)
                    .map(
                        (
                            subCategoryViewModel: SubCategoryTileViewModel,
                            index,
                        ) => (
                            <li
                                key={index}
                                className="leading-[1rem] md:leading-[1.5rem]"
                            >
                                <Link
                                    to={subCategoryViewModel.link}
                                    preventScrollReset={!!preventScrollReset}
                                    className="text-[0.8rem] leading-[1rem] md:text-base md:leading-[1.5rem] transition-colors duration-300 text-gray-900/40 group-hover:text-gray-900 hover:underline"
                                    onClick={() =>
                                        onCategoryClick?.(
                                            subCategoryViewModel.id,
                                        )
                                    }
                                >
                                    {subCategoryViewModel.title}
                                </Link>
                            </li>
                        ),
                    )}

                {category.subCategories?.length > MAX_SUB_CATEGORIES && (
                    <li className="leading-[1rem] md:leading-[1.5rem] opacity-0 transition-opacity duration-300 md:group-hover:opacity-100">
                        <Link
                            to={category.link}
                            preventScrollReset={!!preventScrollReset}
                            className="text-[0.8rem] leading-[1rem] md:text-base md:leading-[1.5rem] text-gray-900 font-medium"
                            onClick={() => onCategoryClick?.(category.id)}
                        >
                            <span className="underline">View all</span>
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    );
};
