import React, { useRef } from 'react';
import { useAtomValue } from 'jotai/index';
import { Link } from '@remix-run/react';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import type { ProductTileViewModel } from '@rocco/ui/tile-product/view-models/product';
import { TileMedia } from '@rocco/ui/tile-shared/components/TileMedia';
import { TileBadge } from '@rocco/ui/tile-shared/components/TileBadge';
import { ProductTileAddToCartButton } from '@rocco/ui/tile-product/components/ProductTileAddToCartButton';
import { ProductTileVariants } from './ProductTileVariants';
import { ProductTilePrice } from './ProductTilePrice';
import { TileFollowButton } from '@rocco/ui/tile-shared/components/TileFollowButton';
import { uiContextAtomTileActions } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';
import { uiContextAtomDisplayMode } from '@rocco/states/uiContextAtomDisplayMode';

export const ProductTile = (props: TileViewModel<ProductTileViewModel>) => {
    const { aspectRatio, model: product, section, disableDesignBoard } = props;
    const { isMobile } = useAtomValue(uiContextAtomDisplayMode);
    const tileActions = useAtomValue(uiContextAtomTileActions);
    const tileRef = useRef<HTMLAnchorElement>(null);

    const draftOrPending = product.draft || product.pendingApproval;
    const statusLabel = draftOrPending
        ? product.draft
            ? 'Draft'
            : 'Pending Approval'
        : product.badge;

    return (
        <Link
            to={product.link}
            className="h-full"
            ref={tileRef}
            onClick={() => {
                tileActions?.trackingConfig?.onClick?.(
                    tileRef,
                    product.id,
                    product.professional.id,
                    section,
                );
            }}
            prefetch="intent"
        >
            <div className="flex h-full flex-col gap-4">
                <div className="group relative h-auto">
                    <TileMedia
                        aspectRatio={aspectRatio}
                        images={product.images}
                        video={product.video}
                        onSlideChange={direction => {
                            tileActions?.trackingConfig?.onTileMediaSliderSlideChange?.(
                                direction,
                                product.id,
                            );
                        }}
                    />

                    <div className="hidden md:block absolute inset-0 bg-gray-900/10 opacity-0 transition-opacity duration-300 md:group-hover:opacity-100 rounded-tile" />

                    {statusLabel && (
                        <div className="absolute left-4 top-4">
                            <TileBadge title={statusLabel} />
                        </div>
                    )}

                    <div className="absolute right-4 top-3.5 flex gap-1 md:invisible md:group-hover:visible md:group-hover:opacity-100 md:opacity-0 transition-opacity duration-300">
                        <TileFollowButton
                            pinId={product.id}
                            pinType="ProductPin"
                            disabled={disableDesignBoard}
                        />
                        {product.canPurchase && (
                            <ProductTileAddToCartButton to={product.link} />
                        )}
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                        <span className="line-clamp-1 overflow-hidden text-ellipsis text-sm md:text-base font-medium tracking-[0.03rem] md:tracking-[0.02rem]">
                            {product.title}
                        </span>
                        <span className="line-clamp-1 overflow-hidden text-ellipsis text-sm md:text-base font-medium tracking-[0.03rem] md:tracking-[0.02rem] text-gray-900/40">
                            {product.professional.name}
                        </span>
                    </div>

                    {product.price ? (
                        <div className="flex items-center justify-between gap-2 text-sm md:text-base text-gray-900/40">
                            <ProductTilePrice price={product.price} />
                            {product.variants && (
                                <ProductTileVariants
                                    variants={product.variants}
                                    limit={isMobile ? 3 : 5}
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </Link>
    );
};
