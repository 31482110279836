import React from 'react';
import { ReactComponent as CartIcon } from '@rocco/icons/svg/cart.svg';
import { Button } from '@rocco/components/button';
import type { ButtonProps } from '@rocco/components/button/button';
export const ProductTileAddToCartButton = (
    props: Omit<ButtonProps, 'size' | 'color'> & {
        to: string;
    },
) => {
    return (
        <Button
            size="square42"
            color="secondary70"
            {...props}
            onClick={e => {
                props.onClick && props.onClick(e);
            }}
        >
            <CartIcon className="w-4.5 fill-gray-900" />
        </Button>
    );
};
