import React, { useRef, useState } from 'react';
import { ClientOnly } from 'remix-utils';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselPagination,
    CarouselPrevious,
    CarouselNext,
} from '@rocco/components/carousel';
import { Image } from '@rocco/components/image';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import type { VideoPlayerError } from '@rocco/components/video-player/video-player';
import { VideoPlayer } from '@rocco/components/video-player/video-player';
import { useLogger } from '@archipro-website/logger/client';
import { CarouselOptions } from '@rocco/components/carousel/carousel';
import { cn } from '@rocco/utils/cn';
import { useAtomValue } from 'jotai';
import { uiContextAtomDisplayMode } from '@rocco/states/uiContextAtomDisplayMode';
export interface ImageModel {
    src: string;
    alt?: string;
    multiplied?: boolean;
}

const _tileMediaVariants = cva('w-full', {
    variants: {
        aspectRatio: {
            featured: 'h-full', // there's no aspect ratio for featured tile - it should take all available space in the grid
            default: 'aspect-tile-default',
            article: 'aspect-tile-article',
            category: 'aspect-tile-category',
            square: 'aspect-square',
        },
        rounded: {
            true: 'rounded-tile overflow-hidden',
            false: '',
        },
    },
    defaultVariants: {
        aspectRatio: 'default',
        rounded: true,
    },
});

const _tileMediaSizes = cva('', {
    variants: {
        sizes: {
            // yep, it works with any strings not just css classes :)
            featured:
                '(min-width: 1921px) 595px, ((min-width: 1024px) and (max-width: 1920px)) 31vw, 100vw',
            default:
                '(min-width: 1921px) 595px, ((min-width: 1024px) and (max-width: 1920px)) 31vw, 100vw',
            article:
                '(min-width: 1921px) 595px, ((min-width: 1024px) and (max-width: 1920px)) 31vw, 100vw',
            category:
                '(min-width: 1921px) 595px, ((min-width: 1024px) and (max-width: 1920px)) 31vw, 100vw',
            square: '(min-width: 1921px) 348px, ((min-width: 1024px) and (max-width: 1920px)) 18vw, 49vw',
        },
    },
});

const _tileMediaImageVariants = cva('relative h-full overflow-hidden', {
    variants: {
        gradiantOverlay: {
            true: 'after:absolute after:inset-0 after:block after:bg-gradient-to-b after:from-transparent after:to-black after:opacity-60',
        },
        multiplied: {
            true: 'bg-[#f2f1f0]',
        },
    },
    defaultVariants: {
        gradiantOverlay: false,
        multiplied: false,
    },
});

export type TileMediaAspectRatio = VariantProps<
    typeof _tileMediaVariants
>['aspectRatio'];

interface TileMediaImageProps {
    images: ImageModel[];
    imagesLimit: number;
    fallbackAlt?: string;
    gradiantOverlay?: boolean;
    onSlideChange?: (direction: 'CarouselPrev' | 'CarouselNext') => void;
}

interface TileMediaVideoProps {
    video: string[];
    fallbackImage?: ImageModel;
}

interface TileMediaProps
    extends Partial<TileMediaImageProps>,
        Partial<TileMediaVideoProps>,
        VariantProps<typeof _tileMediaVariants>,
        VariantProps<typeof _tileMediaSizes> {}

// TileMedia component to display either images or video based on the provided props
export const TileMedia = (props: TileMediaProps) => {
    const {
        aspectRatio,
        images,
        imagesLimit = 5,
        video,
        fallbackAlt,
        gradiantOverlay,
        onSlideChange,
    } = props;

    const imagesCount = images?.length ?? 0;
    const videoFilesCount = video?.length ?? 0;
    const sizes = _tileMediaSizes({ sizes: aspectRatio });

    // Ensure that either images or video is provided
    if (imagesCount < 1 && videoFilesCount < 1) {
        console.error('You should pass either an image or video');
    }

    return (
        <div className={_tileMediaVariants({ aspectRatio })}>
            {video && video.length > 0 ? (
                <TileMediaVideo
                    video={video}
                    fallbackImage={images && images[0]}
                />
            ) : (
                (images && imagesCount > 1 && (
                    <TileMediaImageSlider
                        images={images}
                        imagesLimit={imagesLimit}
                        fallbackAlt={fallbackAlt}
                        onSlideChange={onSlideChange}
                        sizes={sizes}
                    />
                )) ||
                (images && images[0] && (
                    <TileMediaImage
                        image={images[0]}
                        fallbackAlt={fallbackAlt}
                        gradiantOverlay={gradiantOverlay}
                        sizes={sizes}
                    />
                ))
            )}
        </div>
    );
};

const TileMediaImage = ({
    image,
    fallbackAlt,
    gradiantOverlay,
    eager,
    className,
    sizes,
}: {
    image: ImageModel;
    fallbackAlt?: string;
    gradiantOverlay?: boolean;
    eager?: boolean;
    className?: string;
    sizes?: string;
}) => {
    return (
        <div
            className={cn(
                _tileMediaImageVariants({
                    gradiantOverlay,
                    multiplied: image.multiplied,
                }),
                className,
            )}
        >
            <Image
                src={image.src}
                alt={image.alt ?? fallbackAlt ?? ''}
                fill={true}
                loading={eager ? 'eager' : 'lazy'}
                fit={image.multiplied ? 'contain' : 'cover'}
                multiplied={image.multiplied}
                sizes={sizes}
            />
        </div>
    );
};

const TileMediaImageSlider = ({
    images,
    imagesLimit,
    onSlideChange,
    sizes,
}: TileMediaImageProps & { sizes?: string }) => {
    // Preload the first two images
    const [loadedImageIndexes, setLoadedImageIndexes] = useState<number[]>([
        0, 1,
    ]);

    const carouselOptions: CarouselOptions = {
        loop: true,
        watchDrag: (api, evt) => {
            // Only allow dragging when the target is directly in this carousel
            // and not part of a parent carousel drag
            const target = evt.target as Element;
            const carouselElement = api.containerNode();

            return (
                carouselElement?.contains(target) &&
                target.closest('.slider-item-image') !== null
            );
        },
    };

    const stagedIndex = useRef<number>(0);
    const imagesCount = Math.min(images.length, imagesLimit);

    const { isDesktop } = useAtomValue(uiContextAtomDisplayMode);

    const handleSlideChange = ({ currentIndex }: { currentIndex: number }) => {
        const isAtStart = currentIndex === 0;
        const isAtEnd = currentIndex === imagesCount - 1;

        const indexesToLoad = [
            currentIndex,
            isAtStart ? null : currentIndex - 1,
            isAtEnd ? null : currentIndex + 1,
        ].filter((i): i is number => i !== null && i >= 0 && i < imagesCount);

        // Only update if new indexes are needed
        setLoadedImageIndexes(prev => {
            const toAdd = indexesToLoad.filter(i => !prev.includes(i));

            return toAdd.length > 0 ? [...prev, ...toAdd] : prev;
        });

        // Optional direction callback
        if (onSlideChange) {
            let direction: 'CarouselNext' | 'CarouselPrev' | undefined;

            if (currentIndex > stagedIndex.current) direction = 'CarouselNext';
            else if (currentIndex < stagedIndex.current)
                direction = 'CarouselPrev';
            else if (
                stagedIndex.current === imagesCount - 1 &&
                currentIndex === 0
            )
                direction = 'CarouselNext';
            else if (
                stagedIndex.current === 0 &&
                currentIndex === imagesCount - 1
            )
                direction = 'CarouselPrev';

            stagedIndex.current = currentIndex;
            if (direction) onSlideChange(direction);
        }
    };

    return (
        <div className="group h-full">
            <Carousel
                options={{ ...carouselOptions }}
                onChangeSlide={handleSlideChange}
            >
                <CarouselContent>
                    {images.slice(0, imagesLimit).map((image, index) => (
                        <CarouselItem key={index}>
                            {loadedImageIndexes.includes(index) && (
                                <TileMediaImage
                                    image={image}
                                    eager={true}
                                    className="slider-item-image"
                                    sizes={sizes}
                                />
                            )}
                        </CarouselItem>
                    ))}
                </CarouselContent>

                {isDesktop ? (
                    <div className="absolute bottom-4 flex w-full justify-between px-4 opacity-0 transition-opacity ease-linear duration-300 md:group-hover:opacity-100">
                        <CarouselPrevious size="square36" color="secondary70">
                            <ChevronLeft className="mr-0.5" />
                        </CarouselPrevious>

                        <div className="flex mt-1.5">
                            <CarouselPagination />
                        </div>

                        <CarouselNext size="square36" color="secondary70">
                            <ChevronRight className="ml-0.5" />
                        </CarouselNext>
                    </div>
                ) : (
                    <div className="absolute bottom-4 flex w-full justify-center px-4">
                        <div className="flex mt-1.5">
                            <CarouselPagination />
                        </div>
                    </div>
                )}
            </Carousel>
        </div>
    );
};

const TileMediaVideo = ({ video, fallbackImage }: TileMediaVideoProps) => {
    const logger = useLogger();

    return (
        <div className="relative h-full overflow-hidden rounded-lg">
            <ClientOnly
                fallback={
                    fallbackImage && <TileMediaImage image={fallbackImage} />
                }
            >
                {() => (
                    <VideoPlayer
                        src={video}
                        fill={true}
                        playsInline={true}
                        controls={false}
                        mode="auto"
                        loop={true}
                        onError={(e?: VideoPlayerError) => {
                            const errorInfo = !e
                                ? 'unknown'
                                : typeof e === 'string'
                                  ? e
                                  : `${e.name}: ${e.message}`;

                            logger.error(
                                `Error while retrieving video. Error: ${errorInfo}`,
                            );
                        }}
                    />
                )}
            </ClientOnly>
        </div>
    );
};
