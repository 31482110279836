import React, { useRef } from 'react';
import { useAtomValue } from 'jotai/index';
import { Link } from '@remix-run/react';
import { ReactComponent as EyeIcon } from '@rocco/icons/svg/eye.svg';
import { ReactComponent as LikeIcon } from '@rocco/icons/svg/like.svg';
import { shortenCount } from '@rocco/utils/format-number';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import type { ProjectTileViewModel } from '@rocco/ui/tile-project/view-models/project';
import { TileMedia } from '@rocco/ui/tile-shared/components/TileMedia';
import { TileBadge } from '@rocco/ui/tile-shared/components/TileBadge';
import { TileFollowButton } from '@rocco/ui/tile-shared/components/TileFollowButton';
import { ProjectTileCounterBadge } from './ProjectTileCounterBadge';
import { cn } from '@rocco/utils/cn';
import { uiContextAtomTileActions } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';

const PROJECT_TILE_MIN_VIEW_COUNT_THRESHOLD = 1;
const PROJECT_TILE_MIN_SAVED_COUNT_THRESHOLD = 100;

export const ProjectTile = (props: TileViewModel<ProjectTileViewModel>) => {
    const { aspectRatio, model: project, section } = props;
    const tileActions = useAtomValue(uiContextAtomTileActions);
    const tileRef = useRef<HTMLAnchorElement>(null);

    const mediaTileStyle = cn(
        'group relative',
        aspectRatio === 'featured' ? 'h-full' : undefined,
    );

    return (
        <Link
            to={project.link}
            className="h-full"
            ref={tileRef}
            onClick={() => {
                tileActions?.trackingConfig?.onClick?.(
                    tileRef,
                    project.id,
                    project.professional.id,
                    section,
                );
            }}
            prefetch="intent"
        >
            <div className="flex h-full flex-col gap-4">
                <div className={mediaTileStyle}>
                    <TileMedia
                        aspectRatio={aspectRatio}
                        images={project.images}
                        video={project.video}
                        onSlideChange={direction => {
                            tileActions?.trackingConfig?.onTileMediaSliderSlideChange?.(
                                direction,
                                project.id,
                            );
                        }}
                    />

                    <div className="hidden md:block absolute inset-0 bg-gray-900/10 opacity-0 transition-opacity duration-300 md:group-hover:opacity-100 rounded-tile" />
                    {project.badge && (
                        <div className="absolute left-4 top-3">
                            <TileBadge title={project.badge} />
                        </div>
                    )}

                    <div className="absolute right-4 top-3.5 flex gap-1 md:opacity-0 transition-opacity duration-300 md:group-hover:opacity-100">
                        <TileFollowButton
                            pinId={project.id}
                            pinType="ProjectPin"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-[0.125rem]">
                        <span className="overflow-hidden text-ellipsis text-nowrap text-sm md:text-base font-medium tracking-[0.03rem] md:tracking-[0.02rem]">
                            {project.title}
                        </span>
                        <span className="overflow-hidden text-ellipsis text-nowrap text-sm md:text-base font-medium tracking-[0.03rem] md:tracking-[0.02rem] text-gray-900/40">
                            {project.professional.name}
                        </span>
                    </div>

                    <div className="flex justify-start gap-[0.5625rem] md:pb-[0.325rem]">
                        {!!project.viewedCount &&
                            project.viewedCount >
                                PROJECT_TILE_MIN_VIEW_COUNT_THRESHOLD && (
                                <ProjectTileCounterBadge
                                    icon={EyeIcon}
                                    value={shortenCount(
                                        project.viewedCount ?? 0,
                                    )}
                                />
                            )}
                        {!!project.savedCount &&
                            project.savedCount >
                                PROJECT_TILE_MIN_SAVED_COUNT_THRESHOLD && (
                                <ProjectTileCounterBadge
                                    icon={LikeIcon}
                                    value={shortenCount(
                                        project.savedCount ?? 0,
                                    )}
                                />
                            )}
                    </div>
                </div>
            </div>
        </Link>
    );
};
