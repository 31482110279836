import * as React from 'react';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { cn } from '@rocco/utils/cn';
import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const contextMenuItemVariants = cva(
    'rocco-context-menu-item relative flex select-none items-center rounded-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
    {
        variants: {
            color: {
                default: 'focus:bg-accent focus:text-accent-foreground',
                destructive:
                    'text-brick-50 focus:bg-accent focus:text-accent-foreground',
            },
            size: {
                default: 'px-2 py-1.5 text-sm',
            },
            cursor: {
                pointer: 'cursor-pointer',
                default: 'cursor-default',
            },
        },
        defaultVariants: {
            color: 'default',
            size: 'default',
            cursor: 'default',
        },
    },
);

export const ContextMenuItem = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Item>,
    OmitStylesComponentProps<typeof ContextMenuPrimitive.Item> &
        VariantProps<typeof contextMenuItemVariants> & { inset?: boolean }
>(({ inset, color, size, cursor, ...props }, ref) => (
    <ContextMenuPrimitive.Item
        ref={ref}
        className={cn(
            contextMenuItemVariants({ color, size, cursor }),
            inset && 'pl-8',
        )}
        {...props}
    />
));

ContextMenuItem.displayName = ContextMenuPrimitive.Item.displayName;
