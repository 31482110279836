import {
    AlertDialog as AlertDialogComponent,
    AlertDialogTrigger,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogAction,
    AlertDialogCancel,
} from '@rocco/components/alert-dialog';

interface AlertDialogProps {
    trigger?: React.ReactNode;
    title: string;
    description: string;
    onConfirm: () => void;
    onCancel?: () => void;
    controlled?: {
        open: boolean;
        setOpen: (open: boolean) => void;
    };
}

export const AlertDialog = ({
    trigger,
    title,
    description,
    onConfirm,
    onCancel,
    controlled,
}: AlertDialogProps) => {
    return (
        <AlertDialogComponent
            {...(controlled
                ? { open: controlled.open, onOpenChange: controlled.setOpen }
                : {})}
        >
            {trigger && (
                <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
            )}
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogDescription>{description}</AlertDialogDescription>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={onCancel}>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onConfirm}>
                        Confirm
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogComponent>
    );
};
