import * as React from 'react';
import { useCarousel } from './carousel';
import { Button } from '../button';
import { SyntheticEvent } from 'react';

interface CarouselNextProps extends React.PropsWithChildren {}

export const CarouselNext = (
    props: CarouselNextProps & React.ComponentProps<typeof Button>,
) => {
    const {
        children,
        color = 'secondary',
        size = 'square40',
        shape = 'default',
        inverted = false,
    } = props;

    const { scrollNext, canScrollPrev, canScrollNext } = useCarousel();
    const enableScroll = canScrollPrev || canScrollNext;

    const onScrollNext = (evt: SyntheticEvent) => {
        evt.preventDefault();
        evt.stopPropagation();
        scrollNext();
    };

    return enableScroll ? (
        <div className="rocco-carousel-next z-20 flex items-center justify-center">
            <Button
                color={color}
                size={size}
                shape={shape}
                disabled={!canScrollNext}
                inverted={inverted}
                onClick={onScrollNext}
            >
                {children}
                <span className="sr-only">Next slide</span>
            </Button>
        </div>
    ) : null;
};
