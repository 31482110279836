import type { ImageModel } from '@rocco/types/image-model';
import { Image } from '@rocco/components/image';

interface ProfessionalTileLogoProps {
    image: ImageModel;
    background: string | null;
}

export const ProfessionalTileLogo = (props: ProfessionalTileLogoProps) => {
    const { image, background } = props;

    return (
        <div
            className="flex size-22 items-center justify-center overflow-hidden rounded-full p-0.5"
            style={{
                backgroundColor: background || 'white',
            }}
        >
            <Image
                src={image.src}
                alt={image.alt ?? 'Logo'}
                size="professionalLogo"
            />
        </div>
    );
};
