import React from 'react';
import { ContextMenu } from '@rocco/ui/context-menu/components/ContextMenu';
import type { ContextMenuItem } from '@rocco/ui/context-menu/components/ContextMenu';
import { TileAdminTimestampInfo } from '@rocco/ui/tile-shared/components/TileAdminTimestampInfo';

import { AlertDialog } from '@rocco/ui/dialog/components/AlertDialog';

interface AdminContextMenuProps {
    children: React.ReactNode;
    onEdit: () => void;
    onRepositionImage: () => void;
    onTogglePin?: () => void;
    onDelete?: () => void;
    statusInfo: {
        created: {
            createdBy: string;
            createdAt: string;
        };
        approved?: {
            approvedBy: string;
            approvedAt: string;
        };
        published?: {
            publishedBy: string;
            publishedAt: string;
        };
        pinned?: boolean;
    };
}

export const AdminContextMenu: React.FC<AdminContextMenuProps> = ({
    children,
    onEdit,
    onRepositionImage,
    onDelete,
    onTogglePin,
    statusInfo,
}) => {
    const { created, approved, published, pinned } = statusInfo;
    const [openDeleteConfirmation, setOpenDeleteConfirmation] =
        React.useState(false);

    const buildMenuItems = () => {
        const actionItems: ContextMenuItem[] = [
            {
                type: 'item',
                render: () => <span>Edit</span>,
                onClick: onEdit,
            },
            {
                type: 'item',
                render: () => <span>Reposition Image</span>,
                onClick: onRepositionImage,
            },
            onTogglePin && {
                type: 'item',
                render: () =>
                    pinned ? (
                        <span>Unpin From Homepage</span>
                    ) : (
                        <span>Pin To Homepage</span>
                    ),
                onClick: onTogglePin,
            },
        ].filter(Boolean) as ContextMenuItem[];

        const deleteSection: ContextMenuItem[] = onDelete
            ? [
                  { type: 'separator' },
                  {
                      type: 'item',
                      render: () => <span>Delete</span>,
                      onClick: () => setOpenDeleteConfirmation(true),
                  },
              ]
            : [];

        const infoSection = [
            { type: 'separator' },
            {
                type: 'label',
                render: () => (
                    <TileAdminTimestampInfo
                        action="created"
                        userFullName={created.createdBy}
                        date={created.createdAt}
                    />
                ),
            },
            approved && {
                type: 'label',
                render: () => (
                    <TileAdminTimestampInfo
                        action="approved"
                        userFullName={approved.approvedBy}
                        date={approved.approvedAt}
                    />
                ),
            },
            published && {
                type: 'label',
                render: () => (
                    <TileAdminTimestampInfo
                        action="published"
                        userFullName={published.publishedBy}
                        date={published.publishedAt}
                    />
                ),
            },
        ].filter(Boolean) as ContextMenuItem[];

        return [...actionItems, ...deleteSection, ...infoSection];
    };

    return (
        <>
            <ContextMenu menuItems={buildMenuItems()}>{children}</ContextMenu>
            {onDelete && (
                <AlertDialog
                    title="Are you absolutely sure?"
                    description="This action cannot be undone. This will permanently delete this product and its associated information and tags."
                    onConfirm={onDelete}
                    controlled={{
                        open: openDeleteConfirmation,
                        setOpen: setOpenDeleteConfirmation,
                    }}
                />
            )}
        </>
    );
};
