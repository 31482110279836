import * as React from 'react';
import { useEffect } from 'react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

import useEmblaCarousel, {
    type UseEmblaCarouselType,
} from 'embla-carousel-react';

export type CarouselApi = UseEmblaCarouselType[1];

type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
export type CarouselOptions = UseCarouselParameters[0];

export interface OnChangeSlideEvent {
    currentIndex: number;
    prevIndex: number;
    nextIndex: number;
}

interface CarouselProps extends React.PropsWithChildren {
    ref?: React.RefObject<HTMLDivElement>;
    options?: CarouselOptions;
    orientation?: 'horizontal' | 'vertical';
    setApi?: (api: CarouselApi) => void;
    onChangeSlide?: (event: OnChangeSlideEvent) => void;
    onSettleSlide?: (event: OnChangeSlideEvent) => void;
}

interface CarouselContextProps extends CarouselProps {
    carouselRef: ReturnType<typeof useEmblaCarousel>[0];
    api: ReturnType<typeof useEmblaCarousel>[1];
    scrollPrev: () => void;
    scrollNext: () => void;
    canScrollPrev: boolean;
    canScrollNext: boolean;
    currentSlide: number;
    totalSlides: number;
}

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

export const useCarousel = () => {
    const context = React.useContext(CarouselContext);

    if (!context) {
        throw new Error('useCarousel must be used within a <Carousel />');
    }

    return context;
};

export const Carousel = (props: CarouselProps) => {
    const {
        ref,
        orientation = 'horizontal',
        options,
        setApi,
        onChangeSlide,
        onSettleSlide,
        children,
    } = props;

    const [carouselRef, api] = useEmblaCarousel(
        {
            ...options,
            axis: orientation === 'horizontal' ? 'x' : 'y',
        },
        [WheelGesturesPlugin()],
    );

    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [totalSlides, setTotalSlides] = React.useState(0);

    const onSelect = React.useCallback(
        (api: CarouselApi) => {
            if (!api) {
                return;
            }

            const currentIndex = api.selectedScrollSnap();
            const totalSlides = api.slideNodes().length;

            setCanScrollPrev(api.canScrollPrev());
            setCanScrollNext(api.canScrollNext());
            setCurrentSlide(currentIndex);
            setTotalSlides(totalSlides);

            if (onChangeSlide) {
                onChangeSlide({
                    currentIndex,
                    prevIndex: (currentIndex - 1 + totalSlides) % totalSlides,
                    nextIndex: (currentIndex + 1) % totalSlides,
                });
            }
        },
        [onChangeSlide],
    );

    const onSettle = React.useCallback(
        (api: CarouselApi) => {
            if (!api) {
                return;
            }

            const currentIndex = api.selectedScrollSnap();
            const totalSlides = api.slideNodes().length;

            onSettleSlide?.({
                currentIndex,
                prevIndex: (currentIndex - 1 + totalSlides) % totalSlides,
                nextIndex: (currentIndex + 1) % totalSlides,
            });
        },
        [onSettleSlide],
    );

    const scrollPrev = React.useCallback(() => api?.scrollPrev(), [api]);

    const scrollNext = React.useCallback(() => api?.scrollNext(), [api]);

    useEffect(() => {
        if (!api || !setApi) {
            return;
        }

        setApi(api);
        setTotalSlides(api.slideNodes().length);
    }, [api, setApi]);

    useEffect(() => {
        if (!api) {
            return;
        }

        onSelect(api);
        api.on('reInit', onSelect);
        api.on('select', onSelect);
        api.on('settle', onSettle);

        return () => {
            api?.off('select', onSelect);
            api?.off('settle', onSettle);
        };
    }, [api, onSelect, onSettle]);

    return (
        <CarouselContext.Provider
            value={{
                carouselRef,
                api,
                options,
                orientation:
                    orientation ||
                    (options?.axis === 'y' ? 'vertical' : 'horizontal'),
                scrollPrev,
                scrollNext,
                canScrollPrev,
                canScrollNext,
                currentSlide,
                totalSlides,
            }}
        >
            <div
                ref={ref}
                className="relative h-full"
                role="region"
                aria-roledescription="carousel"
            >
                {children}
            </div>
        </CarouselContext.Provider>
    );
};
