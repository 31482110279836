import type { ImageModel } from '@rocco/types/image-model';
import type { TileableModel } from '@rocco/ui/tile-shared/view-models/tile';

interface ProfessionalLogoTileViewModel {
    image: ImageModel;
    background: string;
}

export interface ProfessionalTileViewModel {
    id: number;
    title: string;
    image: ImageModel;
    logo: ProfessionalLogoTileViewModel | null;
    category: string;
    services: string[];
    link: string;
    location: string;
    hasAwards: boolean;
    memberSince: number | null;
    video?: string[];
}

export const isProfessionalTileViewModel = (
    model: TileableModel,
): model is ProfessionalTileViewModel =>
    model && 'category' in model && 'memberSince' in model;
