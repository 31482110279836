import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogDescriptionVariants = cva('rocco-alert-dialog-description', {
    variants: {
        variant: {
            default: 'text-sm text-muted-foreground',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogDescription = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Description>,
    OmitStylesComponentProps<typeof AlertDialogPrimitive.Description> &
        VariantProps<typeof alertDialogDescriptionVariants>
>(({ variant, ...props }, ref) => (
    <AlertDialogPrimitive.Description
        ref={ref}
        className={alertDialogDescriptionVariants({ variant })}
        {...props}
    />
));

AlertDialogDescription.displayName =
    AlertDialogPrimitive.Description.displayName;
