import { Badge } from '@rocco/components/badge';

interface ProfessionalTileServicesProps {
    services: string[];
    limit?: number;
}

export const ProfessionalTileServices = (
    props: ProfessionalTileServicesProps,
) => {
    const { services, limit = 2 } = props;

    return services.length > 0 ? (
        <div className="flex w-full gap-2 font-medium">
            {services.slice(0, limit).map((service, index) => (
                <Badge key={index} color="secondary" text="small">
                    {service.toUpperCase()}
                </Badge>
            ))}

            {services.length > limit && (
                <Badge color="secondary" text="small" contentOverflow="visible">
                    &bull;&bull;&bull;
                </Badge>
            )}
        </div>
    ) : (
        // display empty fallback as we need to take the real estate for aligning tiles
        <div className="h-7"></div>
    );
};
