import * as React from 'react';

import { OmitStylesHTMLAttributes } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const alertDialogHeaderVariants = cva('rocco-alert-dialog-header', {
    variants: {
        variant: {
            default: 'flex flex-col space-y-2 text-center sm:text-left',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AlertDialogHeader = ({
    variant,
    ...props
}: OmitStylesHTMLAttributes<HTMLDivElement> &
    VariantProps<typeof alertDialogHeaderVariants>) => (
    <div className={alertDialogHeaderVariants({ variant })} {...props} />
);

AlertDialogHeader.displayName = 'AlertDialogHeader';
