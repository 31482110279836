import React, { useRef } from 'react';
import { useAtomValue } from 'jotai/index';
import { Link } from '@remix-run/react';
import { TileMedia } from '@rocco/ui/tile-shared/components/TileMedia';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import type { ProfessionalTileViewModel } from '@rocco/ui/tile-professional/view-models/professional';
import { TileFollowButton } from '@rocco/ui/tile-shared/components/TileFollowButton';
import { ProfessionalTileLogo } from './ProfessionalTileLogo';
import { ProfessionalTileServices } from './ProfessionalTileServices';
import { uiContextAtomTileActions } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';

export const ProfessionalTile = (
    props: TileViewModel<ProfessionalTileViewModel>,
) => {
    const { aspectRatio, model: professional, section } = props;
    const tileActions = useAtomValue(uiContextAtomTileActions);
    const tileRef = useRef<HTMLAnchorElement>(null);

    return (
        <Link
            to={professional.link}
            className="h-full"
            ref={tileRef}
            onClick={() => {
                tileActions?.trackingConfig?.onClick?.(
                    tileRef,
                    professional.id,
                    undefined,
                    section,
                );
            }}
            prefetch="intent"
        >
            <div className="flex h-full flex-col gap-4">
                <div className="group relative h-full">
                    <TileMedia
                        aspectRatio={aspectRatio}
                        images={[professional.image]}
                        video={professional.video}
                        onSlideChange={direction => {
                            tileActions?.trackingConfig?.onTileMediaSliderSlideChange?.(
                                direction,
                                professional.id,
                            );
                        }}
                    />

                    <div className="hidden md:block absolute inset-0 bg-gray-900/10 opacity-0 transition-opacity duration-300 md:group-hover:opacity-100 rounded-tile" />

                    {professional.logo && (
                        <div className="absolute left-2.5 top-2 md:left-4 md:top-4">
                            <ProfessionalTileLogo
                                image={professional.logo.image}
                                background={professional.logo.background}
                            />
                        </div>
                    )}

                    <div className="absolute right-4 top-3.5 flex gap-1 md:invisible md:group-hover:visible md:opacity-0 transition-opacity duration-300 md:group-hover:opacity-100">
                        <TileFollowButton
                            pinId={professional.id}
                            pinType="ProfessionalPin"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex flex-col md:gap-0.5">
                        <span
                            className="overflow-hidden text-ellipsis text-nowrap text-sm md:text-base font-medium
                        tracking-[0.03rem] md:tracking-[0.02rem] md:h-[1.4rem]"
                        >
                            {professional.title}
                        </span>
                        <span
                            className="overflow-hidden text-ellipsis text-nowrap text-sm md:text-base font-medium
                        tracking-[0.03rem] md:tracking-[0.02rem] text-gray-900/40 md:h-[1.4rem]"
                        >
                            {professional.category}
                        </span>
                    </div>

                    <div className="hidden justify-start gap-2 md:flex">
                        <ProfessionalTileServices
                            services={professional.services}
                        />
                    </div>
                    <div className="flex justify-start gap-2 md:hidden">
                        <ProfessionalTileServices
                            limit={1}
                            services={professional.services}
                        />
                    </div>
                </div>
            </div>
        </Link>
    );
};
